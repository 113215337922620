import faqServices from '../../../../services/faq'
import { VueEditor } from 'vue2-editor'
import _ from 'lodash'
export default {
    components: {
        VueEditor
    },
    data() {
        return {
            item: {
                faq_id: null,
                order_num: null,
                title: null,
                content: null
            },
            formType: 'INSERT'
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'New FAQ'
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update FAQ'
                }
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(item) {
            this.formType = 'UPDATE'
            this.item = _.cloneDeep(item)
            this.$refs.modal.show()
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT') {
                this.createFaq()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.updateFaq()
            }
        },
        createFaq() {
            faqServices.createFaq(this.item).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        updateFaq() {
            faqServices.updateFaq(this.item).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        }
    }
}